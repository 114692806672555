import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import OutlinedButton from "../common/OutlinedButton";
import StyledTableCell from "../common/StyledTableCell";

function EtfFundDetails({ open, setOpen, etfStrategy }) {
    const handleClose = () => {
        setOpen(false);
    };

    const brokers = Object.keys(etfStrategy.basket);
    console.log(brokers)
    const [selectedBroker, setSelectedBroker] = useState(brokers[0] || "");

    const handleBrokerChange = (event) => {
        setSelectedBroker(event.target.value);
    };

    const etfFunds = [];
    let tiValue = null;
    if (selectedBroker && etfStrategy.basket[selectedBroker]) {
        Object.entries(etfStrategy.basket[selectedBroker]).forEach(([fund, details]) => {
            if (fund === "ti") {
                tiValue = details;
            } else if (details && typeof details === "object") {
                etfFunds.push({
                    name: fund || "NA",
                    lots: details.lots || 0,
                    avg_price: details.avg_price || 0
                });
            }
        });
        console.log(etfFunds)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontFamily: "Poppins", color: "#3E4784" }}>
                {etfStrategy.name} - Funds
            </DialogTitle>
            <DialogContent dividers>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Select Broker</InputLabel>
                    <Select value={selectedBroker} onChange={handleBrokerChange} label="Select Broker">
                        {brokers.map((broker, idx) => (
                            <MenuItem key={idx} value={broker}>{broker}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {etfFunds.length > 0 ? (
                    <TableContainer component={Paper} className="mt-5">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">ETF Fund</StyledTableCell>
                                    <StyledTableCell align="center">Lots</StyledTableCell>
                                    <StyledTableCell align="center">Average Price</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {etfFunds.map((fund, idx) => (
                                    <TableRow key={idx}>
                                        <StyledTableCell align="center">
                                            {fund.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {fund.lots}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {fund.avg_price}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <span className="mt-5 font-bold text-sm">Funds Data not available</span>
                )}
            </DialogContent>
            <DialogActions>
                {tiValue !== null && (
                    <Typography variant="body2" sx={{ marginRight: "auto", fontWeight: "bold" }}>
                        Invested: {tiValue}
                    </Typography>
                )}
                <OutlinedButton onClick={handleClose} size="small">
                    Close
                </OutlinedButton>
            </DialogActions>
        </Dialog>
    );
}

export default EtfFundDetails;
