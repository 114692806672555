import React, { useState } from 'react'
import TextInput from '../components/common/TextInput'
import { Autocomplete, InputAdornment, IconButton } from '@mui/material'
import OutlinedButton from '../components/common/OutlinedButton'
import ColoredButton from '../components/common/ColoredButton'
import { TipsAndUpdates, Add, Remove } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import FeedbackSnackbar from '../components/common/FeedbackSnackbar'
import LoadingBackdrop from '../components/common/LoadingBackdrop'
import { createAxiosInstance } from '../Utils/axiosConfig'

const AddEtfStrategy = () => {

    const location = useLocation();
    const INCREMENT_AMOUNT = 1000;

    const [etfStrategyDetails, setEtfStrategyDetails] = useState(() => {
        const details = location?.state?.strategyDetails ?? {
            name: "",
            amount: 0,
            costavg: 0,
            takeprofit: 0,
            add_profit: false,
             // funds: [],
            user: null,
        };
        
        // If we're in edit mode, store the original amount
        if (location?.state?.mode === 'edit' && location?.state?.strategyDetails) {
            details.previousAmount = location.state.strategyDetails.amount;
        }
        
        return details;
    });
   

    const [mode] = useState(location?.state?.mode);

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        
        setEtfStrategyDetails(prev => ({
            ...prev,
            [name]: newValue
        }));
    }


    const handleAmountIncrement = () => {
        setEtfStrategyDetails(prev => ({
            ...prev,
            amount: Number(prev.amount) + INCREMENT_AMOUNT
        }))
    }

    const handleAmountDecrement = () => {
        setEtfStrategyDetails(prev => ({
            ...prev,
            amount: Math.max(100000, Number(prev.amount) - INCREMENT_AMOUNT)
        }))
    }

    // const handleFundsChange = (e, value) => {
    //     setEtfStrategyDetails(prev => ({
    //         ...prev,
    //         funds: value
    //     }))
    // }

    const validateInput = () => {
        if (!etfStrategyDetails.name || etfStrategyDetails.name.length > 100) {
            return "Name must be between 1 and 100 characters";
        }
        if (etfStrategyDetails.amount < 100000 || etfStrategyDetails.amount > 5000000) {
            return "Amount must be between 100,000 and 5,000,000";
        }
        return null;
    }

    const handleCreate = async () => {
        const validationError = validateInput();
        if (validationError) {
            setSnackBarOpen(true);
            setDisplayMsg({
                type: "error",
                msg: validationError
            });
            return;
        }

        try {
            setLoading(true);
            const axiosInstance = createAxiosInstance(true);

            const response = mode === 'edit' ? await axiosInstance.put('trades/etf/', {
                ...etfStrategyDetails,
                id: etfStrategyDetails.id,
                user: JSON.parse(localStorage.getItem("loggedInUser")).pk,
            }) : await axiosInstance.post('trades/etf/', {
                ...etfStrategyDetails,
                user: JSON.parse(localStorage.getItem("loggedInUser")).pk,
            })

            if (response.status === 200 || response.status === 201) {
                setSnackBarOpen(true);
                setDisplayMsg({
                    type: "success",
                    msg: mode === "edit" ? "ETF strategy edited successfully" : "ETF strategy created successfully",
                });
                setTimeout(() => {
                    navigate('/strategies');
                }, 1500);
            }
        } catch (err) {
            let msg = '';
            if (err.request.status === 404)
                msg = err.message;
            else if (err.request.status === 401 || err.request.status === 400) {
                Object.entries(err.response.data).forEach(([key, value]) => {
                    msg += key + ': ' + value + "\n";
                });
            } else {
                msg = "There was some error in creating this strategy"
            }

            setSnackBarOpen(true);
            setDisplayMsg({
                type: "error",
                msg: msg
            })
        } finally {
            setLoading(false);
        }
    }

    const handleCancel = () => {
        navigate('/strategies')
    }

    return (
        <div className="h-full flex flex-col">
        <h1 className="text-4xl font-medium text-gray-900 tracking-tight">
            {mode === "edit" ? "Edit ETF Strategy" : "Create ETF Strategy"}
        </h1>

        <div className="flex justify-between mt-16 w-full">
            <div className='flex flex-col gap-6 w-full'>
                <div className="flex flex-col gap-2 w-1/2">
                    <label className="font-medium text-sm">
                        Strategy name<span className="text-red-700">*</span>
                    </label>
                    <TextInput
                        value={etfStrategyDetails.name}
                        name="name"
                        size="small"
                        fullWidth
                        placeholder="Enter strategy name"
                        autoComplete="off"
                        onChange={handleChange}
                        inputProps={{ maxLength: 100 }}
                    />
                </div>

                <div className="flex flex-col gap-2 w-1/2">
                <label className="font-medium text-sm flex items-center gap-2">
                    Total Investment Amount<span className="text-red-700">*</span>
                    {mode === "edit" && etfStrategyDetails.previousAmount && (
                        <span className="text-sm text-gray-600 ml-2">
                            Previous: Rs. {Number(etfStrategyDetails.previousAmount).toLocaleString()}
                        </span>
                    )}
                </label>
                    <div className="flex items-center">
                        <IconButton onClick={handleAmountDecrement}>
                            <Remove />
                        </IconButton>
                        <TextInput
                            value={etfStrategyDetails.amount}
                            name="amount"
                            size="small"
                            fullWidth
                            type="number"
                            placeholder="Enter total investment amount"
                            autoComplete="off"
                            InputProps={{ 
                                startAdornment: <InputAdornment position='start'>Rs. </InputAdornment>
                            }}
                            inputProps={{
                                min: 100000,
                                max: 5000000
                            }}
                            onChange={handleChange}
                        />
                        <IconButton onClick={handleAmountIncrement}>
                            <Add />
                        </IconButton>
                    </div>
                </div>

                <div className="flex flex-col gap-2 w-1/2">
                    <label className="font-medium text-sm">
                        Cost Average %<span className="text-red-700">*</span>
                    </label>
                    <TextInput
                        value={etfStrategyDetails.costavg}
                        name="costavg"
                        size="small"
                        fullWidth
                        type='number'
                        placeholder="Enter cost average %"
                        autoComplete="off"
                        InputProps={{ endAdornment: <InputAdornment position='end'>% </InputAdornment> }}
                        onChange={handleChange}
                    />
                </div>

                <div className="flex flex-col gap-2 w-1/2">
                    <label className="font-medium text-sm">
                        Profit %<span className="text-red-700">*</span>
                    </label>
                    <TextInput
                        value={etfStrategyDetails.takeprofit}
                        name="takeprofit"
                        size="small"
                        fullWidth
                        type='number'
                        placeholder="Enter profit %"
                        autoComplete="off"
                        InputProps={{ endAdornment: <InputAdornment position='end'>% </InputAdornment> }}
                        onChange={handleChange}
                    />
                </div>

                <div className="flex items-center gap-2 w-1/2">
                    <input
                        type="checkbox"
                        name="add_profit"
                        checked={etfStrategyDetails.add_profit}
                        onChange={handleChange}
                        className="w-4 h-4"
                    />
                    <label className="font-medium text-sm">Add Profit</label>
                </div>
            </div>

            <div className='py-6 px-9 bg-[#D9D9D9] bg-opacity-50'>
                <div className='flex flex-col gap-7'>
                    <div className='flex gap-4 text-black items-center'>
                        <TipsAndUpdates sx={{ width: 32, height: 32 }} />
                        <span className='font-medium text-3xl'>Guidelines</span>
                    </div>

                    <ul className='list-disc list-inside'>
                        <li>Cost averaging involves regularly investing a fixed amount of money into ETFs, regardless of market fluctuations.</li>
                        <li className='mt-3'>By investing periodically, you avoid the risk of trying to time the market, which can be challenging.</li>
                        <li className='mt-3'>Total investment must be between Rs. 100,000 and Rs. 5,000,000.</li>
                        <li className='mt-3'>Cost average % is the percentage in dip of the ETF price that would trigger a fresh purchase.</li>
                        <li className='mt-3'>Profit % is the percentage at which investments are to be sold strategically.</li>
                        <li className='mt-3'>Use + and - buttons to adjust the investment amount by Rs. 1,000.</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="flex gap-7 mt-auto">
            <OutlinedButton
                variant="outlined"
                size="small"
                onClick={handleCancel}
            >
                Cancel
            </OutlinedButton>
            <ColoredButton
                variant="contained"
                size="small"
                onClick={handleCreate}
            >
                {mode === "edit" ? "Save" : "Create"}
            </ColoredButton>
        </div>

        <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />
        {loading && <LoadingBackdrop loading={loading} />}
    </div>
    )
}

export default AddEtfStrategy
