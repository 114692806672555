import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Alert,
  Snackbar,
  CircularProgress
} from '@mui/material';
import { createAxiosInstance } from '../Utils/axiosConfig';

const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY 

const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const { planName, planDetails, duration } = location.state || {};
  
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    const verifyPayment = async (paymentData) => {
        try {
          console.log('Verifying payment with data:', paymentData);
          
          if (!paymentData.payment_id || !paymentData.subscription_id || !paymentData.signature) {
            throw new Error('Missing required payment verification data');
          }
      
          const axiosInstance = createAxiosInstance(true);
          
          const requestBody = {
            payment_id: paymentData.payment_id,
            subscription_id: paymentData.subscription_id,
            signature: paymentData.signature
          };
      
          const response = await axiosInstance.post(
            '/payment/payment/verify/', 
            requestBody
          );
      
          console.log('Full verification response:', {
            status: response.status,
            data: response.data,
            headers: response.headers
          });
    
          if (response.status === 201 || response.status === 200) {

            const successData = {
              ...paymentData,
              planName,
              amount: planDetails.amount,
              duration,
              transactionDetails: response.data
            };
      
            setPaymentDetails(successData);
    
            navigate('/payment-success', {
              state: successData
            });
            
            return;
          }
      
          throw new Error('Payment verification failed');
      
        } catch (error) {
          let errorMessage = 'Payment verification failed. Please try again.';
      
          if (error.response) {
            console.error('Server responded with error:', {
              status: error.response.status,
              data: error.response.data,
              headers: error.response.headers
            });
            errorMessage = error.response.data?.detail || error.response.data?.message || errorMessage;
          } else if (error.request) {
            console.error('No response received:', error.request);
            errorMessage = 'Unable to reach the payment server. Please check your connection.';
          } else {
            console.error('Error setting up request:', error.message);
            errorMessage = error.message;
          }
      
          setError(errorMessage);
          setPaymentDetails({
            error: errorMessage,
            paymentData
          });
        } finally {
          setLoading(false);
        }
      };

      const handlePayment = async () => {
        try {
          setLoading(true);
          setError(null);
          
          const axiosInstance = createAxiosInstance(true);
          const response = await axiosInstance.post(
            `/payment/subscribe/?plan=${planDetails.plan_id}`
          );
      
          if (!response.data || !response.data.id) {
            throw new Error('Failed to create subscription');
          }
      
          const options = {
            key: RAZORPAY_KEY,
            subscription_id: response.data.id,
            name: 'Trade Buddys',
            description: `${planName.toUpperCase()} Plan - ${duration === 1 ? 'Monthly' : duration === 3 ? 'Quarterly' : 'Yearly'}`,
            theme: {
              color: '#3f51b5'
            },
            handler: function (response) {
              console.log('Payment successful! Response:', response);
              
              const verificationData = {
                payment_id: response.razorpay_payment_id,
                subscription_id: response.razorpay_subscription_id,
                signature: response.razorpay_signature
              };
              
              console.log('Sending for verification:', verificationData);
              verifyPayment(verificationData);
            },
            modal: {
              ondismiss: function() {
                console.log('Payment modal dismissed');
                setLoading(false);
                setError('Payment cancelled');
              }
            }
          };
      
          if (window.Razorpay) {
            const razorpayInstance = new window.Razorpay(options);
            razorpayInstance.open();
          } else {
            throw new Error('Razorpay SDK not loaded');
          }
          
        } catch (error) {
          console.error('Payment initialization failed:', error);
          setError(error.message || 'Failed to initialize payment. Please try again.');
          setLoading(false);
        }
      };
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Checkout
        </Typography>
        
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Typography variant="h6" gutterBottom>
              Order Summary
            </Typography>
            <List>
              <ListItem>
                <ListItemText 
                  primary="Plan" 
                  secondary={`${planName.toUpperCase()} - ${duration === 1 ? 'Monthly' : duration === 3 ? 'Quarterly' : 'Yearly'}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Amount" 
                  secondary={`₹${(planDetails.amount || 0) }`}
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Duration" 
                  secondary={`${duration} ${duration === 1 ? 'month' : 'months'}`}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={5}>
            <Paper sx={{ p: 3, bgcolor: 'grey.50' }}>
              <Typography variant="h6" gutterBottom>
                Total
              </Typography>
              <Typography variant="h4" gutterBottom>
                ₹{(planDetails.amount || 0) }
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                {duration === 1 ? 'Monthly' : duration === 3 ? 'Quarterly' : 'Yearly'} billing
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={handlePayment}
                disabled={loading}
                sx={{ mt: 2 }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Proceed to Payment'}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Checkout;
