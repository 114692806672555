import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { 
    planName, 
    amount, 
    duration,
    razorpay_payment_id,
    razorpay_subscription_id,
    razorpay_signature 
  } = location.state || {};

  return (
    <Container maxWidth="sm" sx={{ py: 8 }}>
      <Paper sx={{ p: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
          <Typography variant="h4" gutterBottom>
            Payment Successful!
          </Typography>
          <Typography color="text.secondary" paragraph>
            Thank you for your subscription to the {planName} plan.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />
        
        <List>
          <ListItem>
            <ListItemText 
              primary="Plan Details"
              secondary={`${planName} - ${duration} ${duration === 1 ? 'month' : 'months'}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Amount Paid"
              secondary={`₹${(amount || 0) / 100}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Payment ID"
              secondary={razorpay_payment_id}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Subscription ID"
              secondary={razorpay_subscription_id}
            />
          </ListItem>
        </List>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => navigate('/home')}
          >
            Go to Dashboard
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default PaymentSuccess;