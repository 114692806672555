import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { createAxiosInstance } from '../Utils/axiosConfig';

const PricingTier = ({ tier, selectedDuration, onSubscribe }) => {
  // Find the correct price based on plan name and duration
  const planPrice = tier.prices.find(p => p.duration === selectedDuration);
  
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {tier.title}
        </Typography>
        {tier.mostPopular && (
          <Typography variant="subtitle1" color="primary">
            Most Popular
          </Typography>
        )}
        
        <Box sx={{ my: 2 }}>
          <Typography variant="h3" component="span">
            ₹{(planPrice?.amount || 0) }
          </Typography>
          <Typography variant="h6" component="span">
            /{selectedDuration === 1 ? 'month' : selectedDuration === 3 ? '3 months' : 'year'}
          </Typography>
        </Box>
        <Typography color="text.secondary">
          {tier.description}
        </Typography>
        <List>
          {tier.features.map((feature) => (
            <ListItem key={feature}>
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant={tier.mostPopular ? "contained" : "outlined"}
          color="primary"
          onClick={() => onSubscribe(tier.title.toLowerCase(), planPrice)}
        >
          Subscribe Now
        </Button>
      </CardActions>
    </Card>
  );
};

const Pricing = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      const token = JSON.parse(localStorage.getItem("userAuthToken"))?.userToken?.accessToken;
      if (!token) {
        setError('Authorization token is missing.');
        setLoading(false);
        return;
      }
    
      try {
        const axiosInstance = createAxiosInstance(true);
        const response = await axiosInstance.get('/payment/plans/');
        setPlans(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load pricing plans');
        setLoading(false);
      }
    };
    
    fetchPlans();
  }, []);

  // Organize plans by name
  const organizedPlans = React.useMemo(() => {
    const planNames = ['basic', 'standard', 'pro'];
    return planNames.map(name => ({
      title: name.charAt(0).toUpperCase() + name.slice(1),
      prices: plans.filter(p => p.name === name),
      description: `Perfect for ${name === 'basic' ? 'small teams' : name === 'standard' ? 'growing teams' : 'large organizations'}`,
      features: name === 'basic' 
        ? ['5 users included', '2GB storage', 'Basic support']
        : name === 'standard'
        ? ['20 users included', '10GB storage', 'Priority support']
        : ['Unlimited users', '100GB storage', '24/7 support'],
      mostPopular: name === 'standard'
    }));
  }, [plans]);

  const handleSubscribe = (planName, planDetails) => {
    navigate('/checkout', { 
      state: { 
        planName,
        planDetails,
        duration: selectedDuration
      }
    });
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Pricing Plans for Teams of All Sizes
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Choose an affordable plan that's packed with the best features for engaging your audience,
          creating customer loyalty, and driving sales.
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Billing Duration</InputLabel>
            <Select
              value={selectedDuration}
              onChange={(e) => setSelectedDuration(e.target.value)}
              label="Billing Duration"
            >
              <MenuItem value={1}>Monthly</MenuItem>
              <MenuItem value={3}>Quarterly</MenuItem>
              <MenuItem value={12}>Yearly</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Grid container spacing={4}>
          {organizedPlans.map((tier) => (
            <Grid item key={tier.title} xs={12} md={4}>
              <PricingTier 
                tier={tier} 
                selectedDuration={selectedDuration}
                onSubscribe={handleSubscribe}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Pricing;
