import { ArrowDownward, ArrowUpward, Delete, Edit, HighlightOff, PlayCircle, PauseCircle, PlayArrow } from '@mui/icons-material';
import { Chip, Link, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NoRowsOverlay from '../common/NoRowsOverlay';
import StyledDataGrid from '../common/StyledDataGrid';
import ConfirmDialog from '../common/ConfirmDialog';
import { createAxiosInstance } from '../../Utils/axiosConfig';
import FeedbackSnackbar from '../common/FeedbackSnackbar';
import LoadingBackdrop from '../common/LoadingBackdrop';
import DialogBoxWrapper from '../common/DialogBoxWrapper';
import { IconContext } from 'react-icons';
import BrokerSelectDialog from '../broker-setup/BrokerSelectDialog';
import { BiLogInCircle } from 'react-icons/bi';
import { BsCheck2Circle } from 'react-icons/bs';
import EtfFundDetails from './EtfFundDetails';


function renderPL(props) {
    const { value } = props;

    return (
        <h1
            className={`font-semibold ${value > 0 ? "text-green-500" : "text-red-500"
                }`}
        >
            {value}
        </h1>
    );
}



function renderStatus(props) {
    const { value } = props;
    
    switch (value) {
        case "placed":
            return <Chip label="In execution" color="error" />;
        case "paused":
            return <Chip label="Paused" color="warning" />;
        default:
            return <Chip label={capitalize(value)} color="success" />;
    }
}

const EtfStrategiesTable = ({ etfStrategies }) => {

    const testStrategy = {
        name: "Test ETF Strategy",
        basket: {
            "paper_trade-1": {
                "ti": 0,
                "ALPHA": {
                    "lots": 41,
                    "avg_price": 48.06,
                    "unrealised_pl": -41.41
                }
            }
        }
    };

    const navigate = useNavigate();

    const columns = [
        {
            field: "name",
            headerName: "Strategy Name",
            headerAlign: "left",
            renderCell: renderStrategyName,
            align: "left",
            flex: 1
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            renderCell: renderStatus,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "amount",
            headerName: "Investment Amount",
            align: "left",
            width: 200
        },
        {
            field: "costavg",
            headerName: "Cost Average",
            align: "center",
            headerAlign: "center",
            valueFormatter: ({ value }) => {
                if (value == null) {
                    return '';
                }
                return `${value} %`;
            },
            width: 180
        },
        {
            field: "takeprofit",
            headerName: "Take Profit %",
            align: "center",
            headerAlign: "center",
            valueFormatter: ({ value }) => {
                if (value == null) {
                    return '';
                }
                return `${value} %`;
            },
            width: 180
        },
        {
            field: "profit",
            headerName: "Profit",
            align: "center",
            headerAlign: "center",
            renderCell: renderPL,
            width: 150
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 135,
            getActions: (params) => {
                const actions = [];
                
                // Execute/Abort action
                if (params.row.status === "running") {
                    actions.push(
                        <GridActionsCellItem
                            icon={<Tooltip title='Abort strategy'><HighlightOff sx={{ color: 'black' }} /></Tooltip>}
                            label="Abort"
                            onClick={() => handleAbortClick(params.id)}
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title='Pause strategy'><PauseCircle sx={{ color: 'black' }} /></Tooltip>}
                            label="Pause"
                            onClick={() => handlePauseClick(params.id)}
                        />
                    );
                } else if (params.row.status === "paused") {
                    actions.push(
                        <GridActionsCellItem
                            icon={<Tooltip title='Resume strategy'><PlayArrow sx={{ color: 'black' }} /></Tooltip>}
                            label="Resume"
                            onClick={() => handleResumeClick(params.id)}
                        />
                    );
                } else {
                    actions.push(
                        <GridActionsCellItem
                            icon={<Tooltip title='Execute strategy'><PlayCircle sx={{ color: 'black' }} /></Tooltip>}
                            label="Execute"
                            onClick={() => handleExecuteClick(params.id)}
                        />
                    );
                }

                // Edit action (only for saved status)
                if (params.row.status === "saved") {
                    actions.push(
                        <GridActionsCellItem
                            icon={<Tooltip title='Edit strategy'><Edit sx={{ color: "black" }} /></Tooltip>}
                            label="Edit"
                            onClick={() => navigate('addStrategy/etf', { state: { strategyDetails: params.row, mode: 'edit' } })}
                        />
                    );
                }

                // Delete action (always available)
                actions.push(
                    <GridActionsCellItem
                        icon={<Tooltip title='Delete strategy'><Delete sx={{ color: "black" }} /></Tooltip>}
                        label="Delete"
                        onClick={handleDeleteClick(params.id)}
                    />
                );

                return actions;
            }
        },
    ];
    function renderStrategyName(props) {
        const { value, id } = props;

        return (
            <div>
                <Link
                    sx={{
                        color: "black",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        textDecoration: "none",
                        borderBottom: "1px solid black",
                    }}
                    component="button"
                    variant="body2"
                    onClick={() => showEtfFundDetails(id)}
                >
                    {value}
                </Link>
            </div>
        );
    }

    const [rows, setRows] = useState(etfStrategies);
    const [promiseArguments, setPromiseArguments] = useState({
        promiseArgs: null,
        msg: "",
    });
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [showBrokerSelectDialog, setShowBrokerSelectDialog] = useState(false);
    const [showAbortConfirmDialog, setShowAbortConfirmDialog] = useState(false);
    const [isPaperTrade, setIsPaperTrade] = useState(false);
    const [brokerAccountsSelected, setBrokerAccountsSelected] = useState({ finvasia: [], angel: [], zerodha: [] });
    const [strategyInFocus, setStrategyInFocus] = useState(null);
    const [etfFundDetailsPopupOpen, setEtfFundDetailsPopupOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });

    const handleExecuteClick = (id) => {
        setShowBrokerSelectDialog(true);
        setStrategyInFocus(id);
    };

    const showEtfFundDetails = (id) => {
        const etfStrategy = etfStrategies.find(etfStrategy => etfStrategy.id === id);
        setStrategyInFocus(etfStrategy)
        setEtfFundDetailsPopupOpen(true);
    }

    const onConfirmBtnClick = async () => {
        if (!isPaperTrade && (brokerAccountsSelected.finvasia.length + brokerAccountsSelected.angel.length + brokerAccountsSelected.zerodha.length === 0)) {
            setSnackBarOpen(true);
            setDisplayMsg({ msg: 'At least one broker account has to be selected', type: "error" });
            return;
        }
        setLoading(true);
        const axiosInstance = createAxiosInstance(true);

        try {
            const response = await axiosInstance.post(`trades/etf/execute/?strategy_id=${strategyInFocus}&paper_trade=${isPaperTrade}`, !isPaperTrade ? { broker_ids: brokerAccountsSelected } : {});

            if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Strategy is in execution", type: "success" });

                setRows(prev => {
                    const newValues = [...prev];
                    const idx = newValues.findIndex((strategy) => strategy.id === strategyInFocus);
                    newValues[idx].status = "running";
                    
                    return newValues;
                })
            }
        } catch (err) {
            let msg = '';
            if (err.request.status === 404)
                msg = err.message;
            else if (err.request.status === 401 || err.request.status === 400)
                msg = msg = err.response.data.detail;
            else
                msg = "Some error has occurred";
            setSnackBarOpen(true);
            setDisplayMsg({ msg: msg, type: "error" });
        } finally {
            setLoading(false);
            setShowBrokerSelectDialog(false);
            setBrokerAccountsSelected({ finvasia: [], angel: [], zerodha: [] });
        }
    }

    const handleDeleteClick = (id) => () => {
        new Promise((resolve, reject) => {
            setPromiseArguments({
                promiseArgs: { resolve, reject, id },
                msg: "Are you sure you want to delete this strategy?",
            });
        });
    };

    const handleDeleteConfirmNo = () => {
        const { resolve, id } = promiseArguments.promiseArgs;
        resolve(id);
        setPromiseArguments({ promiseArgs: null, msg: "" });
    };

    const handleDeleteConfirmYes = async () => {
        const { resolve, id } = promiseArguments.promiseArgs;
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            const response = await axiosInstance.delete(`/trades/etf/?id=${id}`);

            if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "ETF strategy deleted successfully", type: "success" });
                setRows(prev => {
                    let newData = [...prev];
                    newData = newData.filter(strategy => strategy.id !== id);
                    return newData;
                })
            }

        } catch (err) {
            setSnackBarOpen(true);
            setDisplayMsg({ msg: err.message, type: "error" });
        } finally {
            setLoading(false);
            resolve(id);
            setPromiseArguments({ promiseArgs: null, msg: "" });
        }
    };

    const handleAbortClick = (id) => {
        setShowAbortConfirmDialog(true);
        setStrategyInFocus(id);
    }


    const abortStrategy = async () => {
        if (strategyInFocus) {
            setLoading(true);
            try {
                const axiosInstance = createAxiosInstance(true);
                const response = await axiosInstance.post(`trades/etf/abort/?strategy_id=${strategyInFocus}`)
                if (response.status === 200) {
                    setSnackBarOpen(true);
                    setDisplayMsg({ msg: "Strategy aborted successfully", type: "success" });
                    setRows(prev => {
                        const newValues = [...prev];
                        const idx = newValues.findIndex((strategy) => strategy.id === strategyInFocus);
                        newValues[idx].status = "status";

                        return newValues;
                    })
                    setStrategyInFocus(undefined);
                }
            } catch (err) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: err, type: "error" });
            } finally {
                setLoading(false);
                setShowAbortConfirmDialog(false);
            }
        }
    }

    if (loading) {
        return (
            <LoadingBackdrop loading={loading} />
        );
    }


    const handlePauseClick = async (id) => {
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            const response = await axiosInstance.post(`trades/etf/pause/?strategy_id=${id}`);

            if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Strategy paused successfully", type: "success" });
                setRows(prev => {
                    const newValues = [...prev];
                    const idx = newValues.findIndex((strategy) => strategy.id === id);
                    newValues[idx].status = "paused";
                    return newValues;
                });
            }
        } catch (err) {
            setSnackBarOpen(true);
            setDisplayMsg({ 
                msg: err.response?.data?.detail || "Failed to pause strategy", 
                type: "error" 
            });
        } finally {
            setLoading(false);
        }
    };

    const handleResumeClick = async (id) => {
        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            const response = await axiosInstance.post(`trades/etf/resume/?strategy_id=${id}`);

            if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Strategy resumed successfully", type: "success" });
                setRows(prev => {
                    const newValues = [...prev];
                    const idx = newValues.findIndex((strategy) => strategy.id === id);
                    newValues[idx].status = "running";
                    return newValues;
                });
            }
        } catch (err) {
            setSnackBarOpen(true);
            setDisplayMsg({ 
                msg: err.response?.data?.detail || "Failed to resume strategy", 
                type: "error" 
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='h-full w-full'>
           <StyledDataGrid
                rows={rows}
                columns={columns}
                slots={{
                    noRowsOverlay: () => (
                        <NoRowsOverlay msg="You have not created any ETF strategy yet" />
                    ),
                    columnSortedAscendingIcon: () => (
                        <ArrowDownward fontSize="small" sx={{ color: "black" }} />
                    ),
                    columnSortedDescendingIcon: () => (
                        <ArrowUpward fontSize="small" sx={{ color: "black" }} />
                    ),
                }}
                disableRowSelectionOnClick
                checkboxSelection
                disableColumnMenu
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
            />

            {promiseArguments.promiseArgs && (
                <ConfirmDialog
                    promiseArgs={promiseArguments.promiseArgs}
                    msg={promiseArguments.msg}
                    handleNo={handleDeleteConfirmNo}
                    handleYes={handleDeleteConfirmYes}
                />
            )}

            {showAbortConfirmDialog && <DialogBoxWrapper
                iconComp={<IconContext.Provider value={{ color: '#3E4784', size: '1.2em' }}><BsCheck2Circle /></IconContext.Provider>}
                title={'Are you sure?'}
                contentComponent={'Are you sure you want to abort this strategy?'}
                open={showAbortConfirmDialog}
                setOpen={setShowAbortConfirmDialog}
                confirmHandler={abortStrategy}
            />
            }

            {showBrokerSelectDialog && (
                <DialogBoxWrapper
                    iconComp={<IconContext.Provider value={{ color: '#3E4784', size: '1.2em' }}><BiLogInCircle /></IconContext.Provider>}
                    open={showBrokerSelectDialog} setOpen={setShowBrokerSelectDialog}
                    title='Choose broker accounts'
                    subtitle='Choose broker to execute strategy'
                    contentComponent={<BrokerSelectDialog setBrokerAccountsSelected={setBrokerAccountsSelected} setIsPaperTrade={setIsPaperTrade} isPaperTrade={isPaperTrade} />}
                    confirmHandler={onConfirmBtnClick}
                />
            )}

            {etfFundDetailsPopupOpen && (
                <EtfFundDetails
                    open={etfFundDetailsPopupOpen}
                    setOpen={setEtfFundDetailsPopupOpen}
                    etfStrategy={strategyInFocus}
                />

            )}
{/* 
            {etfFundDetailsPopupOpen && (

             <EtfFundDetails open={true} setOpen={() => {}} etfStrategy={testStrategy} />

            )} */}

            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} />}
        </div>
    )
}

export default EtfStrategiesTable